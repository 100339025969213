<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
<!--      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />-->
      <globe-icon />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setLanguage(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
        v-if="false"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import {GlobeIcon} from 'vue-feather-icons'
import {  localize } from 'vee-validate'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    GlobeIcon
  },
  computed: {
    currentLocale() {
      localize(this.$i18n.locale)
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  setup() {
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'th',
        img: require('@/assets/images/flags/fr.png'),
        name: 'Thai',
      },
    ]

    return {
      locales,
    }
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('i18n', lang)
    }
  }
}
</script>

<style>

</style>
