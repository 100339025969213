<template>
  <!-- Error page-->
  <div class="register-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo/>
    </b-link>

    <div class="locale-nav">
      <locale/>
    </div>

    <div class="mt-5 mt-md-3 py-3 px-1 py-md-3 px-md-2 py-lg-4 px-lg-4" v-if="!isShowLoading">
      <work-view-detail :result="result" :is-show-loading="isShowLoading"/>
      <work-view-date-of-work :result="result" :is-show-loading="isShowLoading"/>
      <work-view-contractor :result="result" :is-show-loading="isShowLoading"/>
      <work-view-jsa :work-id="id" :card-collapsed="false"/>
      <work-view-safety-check :work-id="id"/>
      <work-view-checkin-history :work-id="id"/>
      <work-view-activity :work-id="id" :card-collapsed="false"/>
    </div>

      <div class="text-center" v-if="showLogin">
        <b-button variant="primary" class="mb-5 mt-n2" :to="{name: 'login'}">
          <feather-icon icon="LogInIcon"/>
          Sign in
        </b-button>
      </div>


  </div>
  <!-- / Error page-->
</template>

<script>
import {BLink, BButton, BImg, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BFormCheckbox, BFormFile, BOverlay, BContainer} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import WorkViewDetail from "@/components/work/WorkViewDetail"
import WorkViewDateOfWork from "@/components/work/WorkViewDateOfWork"
import WorkViewContractor from "@/components/work/WorkViewContractor"
import WorkViewJsa from "@/components/work/WorkViewJsa"
import WorkViewSafetyCheck from "@/components/work/WorkViewSafetyCheck"
import WorkViewActivity from "@/components/work/WorkViewActivity"
import WorkViewCheckinHistory from "@/components/work/WorkViewCheckinHistory"
import {WorkService} from "@/services"
import Locale from "@core/layouts/components/app-navbar/components/Locale"

const workService = new WorkService()

export default {
  components: {
    Locale,
    VuexyLogo,
    BLink,
    BButton,
    WorkViewDetail,
    WorkViewDateOfWork,
    WorkViewContractor,
    WorkViewJsa,
    WorkViewSafetyCheck,
    WorkViewActivity,
    WorkViewCheckinHistory,
  },
  async created() {
    this.id = this.$route.query.workId
    this.workCode = this.$route.query.workCode
    await this.getData()

    this.isShowLoading = false
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    userData: JSON.parse(localStorage.getItem('userData')),
    id: '',
    workCode: '',
    result: {},
    showLogin: true
  }),
  methods: {
    async getData() {
      let queryResult = await workService.getData(this.id)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.result = result

        this.checkPermission()

        if(this.workCode !== result.workCode) {
          await this.$router.replace({name: 'publicDataNotFound'})
        }

        this.isShowLoading = false
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        // await this.$router.push({name: 'pageWork'})
      }
    },
    checkPermission() {
      if(this.userData) {
        this.showLogin = false
        let {workStepResult} = this.result
        if (workStepResult.groupStatusType === 'inProgress' && workStepResult.permission.includes(this.userData.userGroupId)) {
          this.$router.push({name: 'pageWorkOperation', params: {id: this.id}})
        }
      }
    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.register-wrapper {

  .register-inner {
    position: relative;
    max-width: 900px;
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 2rem;
    left: 2rem;
    margin: 0;

    .brand-text {
      font-weight: 600;
    }
  }

  .locale-nav {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 2rem;
    margin: 0;

    ::marker {
      color: #fff;
    }
  }
}
</style>
