<template>
  <div>
    <b-overlay :show="showLoading">

      <b-card-actions :title="$t('work.btnCheckIn')" action-collapse :collapsed="cardCollapsed">
        <app-collapse accordion type="margin" v-for="(row, key) in results" :key="key">
          <checkin-collapse-item :title="row.workAttendanceDate" :check-in-result="row.workAttendanceStatus">
            <b-row>
              <b-col md="6">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>{{ $t('work.order') }}</th>
                    <th>{{ $t('work.workers') }}</th>
                    <th>{{ $t('user.status') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, workerRow) in row.workAttendanceContractorLists" :key="`checkin-worker${item.workerId}`">
                    <td>{{ workerRow + 1 }}</td>
                    <td>{{ item.workerName }}</td>
                    <td>{{ item.workerStatusName }}</td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col cols="4" v-for="item in row.workAttendanceImageLists" :key="`checkin-image${item.imageId}`">
                    <thumbnail-file :src="item.imageAttach" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </checkin-collapse-item>
        </app-collapse>
      </b-card-actions>
    </b-overlay>
  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BMedia, BImg} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import CheckinCollapseItem from '@/components/work/CheckinCollapseItem'

import {WorkService} from "@/services"

const workService = new WorkService()

export default {
  name: "WorkViewSafetyCheck",
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BMedia,
    BImg,
    BCardActions,
    AppCollapse,
    CheckinCollapseItem,
  },
  props: {
    workId: {
      required: true
    },
    cardCollapsed: {
      Type: Boolean,
      default: false
    }
  },
  created() {
    this.listData()
  },
  data: () => ({
    results: [],
  }),
  watch: {
    workId() {
      this.listData()
    }
  },
  computed: {
    showLoading() {
      return this.isShowLoading
    },
  },
  methods: {
    async listData() {
      let queryResult = await workService.checkInHistory(this.workId)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {results} = data
        this.results = results

        this.isShowLoading = false
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.media-list .media {
  padding-left: 0;
}
</style>