<template>
  <div>
    <b-overlay>
      <b-card-actions :title="$t('work.headerJSA')" action-collapse :collapsed="cardCollapsed">
        <b-card-text>
          <vue-good-table
              mode="remote"
              :columns="tableData.columns"
              :rows="tableData.rows"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="tableData.totalRecords"
              :isLoading.sync="tableData.isLoading"
              :pagination-options="{ enabled: true }"
          >
            <template
                slot="table-row"
                slot-scope="props"
            >
              <div v-if="props.column.field === 'jsaAttach'">
                <thumbnail-file :src="props.row.jsaAttach" :description="props.row.jsaAttachFilename"/>
              </div>
              <div v-else-if="props.column.field === 'jsaStatusName'">
                <b-badge :variant="statusColor(props.row.jsaStatusId)">{{ switchLanguage(props.row.jsaStatusNameTh, props.row.jsaStatusNameEn) }}</b-badge>
              </div>

              <div v-else>{{ props.formattedRow[props.column.field] }}</div>
            </template>

            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('general.tableShowing') }} 1 {{ $t('general.tableTo') }}
            </span>
                  <b-form-select
                      v-model="tableData.pageLength"
                      :options="tableData.pageLengthOption"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> {{ $t('general.tableOf') }} {{ props.total }} {{ $t('general.tableEntries') }} </span>
                </div>
                <div>
                  <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="tableData.pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>

        </b-card-text>
      </b-card-actions>
    </b-overlay>
  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BPagination, BFormSelect, BBadge} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {VueGoodTable} from 'vue-good-table'
import {WorkService} from '@/services'
import tableMixins from "@/mixins/tableMixins"

const workService = new WorkService()

export default {
  name: "WorkViewDetail",
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BFormInput,
    BButton,
    BOverlay,
    BFormSelect,
    BBadge,
    BCardActions,
    VueGoodTable,
  },
  mixins: [tableMixins],
  props: {
    workId: {
      required: true
    },
    cardCollapsed: {
      Type: Boolean,
      default: false
    }
  },
  created() {
    this.tableData.columns = [
      {
        label: this.$t('work.jobDescription'),
        field: 'jsaJobDescription',
        width: '30%',
        sortable: false
      },
      {
        label: this.$t('work.identifyHazards'),
        field: 'jsaIdentifyHazards',
        width: '20%',
        sortable: false
      },
      {
        label: this.$t('work.preventiveMeasures'),
        field: 'jsaPreventiveControl',
        width: '20%',
        sortable: false
      },
      {
        label: this.$t('work.attachFile'),
        field: 'jsaAttach',
        width: '10%',
        sortable: false
      },
      {
        label: this.$t('work.status'),
        field: 'jsaStatusName',
        width: '20%',
        sortable: false
      },
    ]
    this.listData()
  },
  data: () => ({

  }),
  watch: {
    tableChange() {
      this.listData()
    }
  },
  methods: {
    listData() {
      this.getTableQueryString()
      workService.listJsaData(this.workId, this.tableQueryString).then(queryResult => {
        let {isSuccess, data} = queryResult
        if (isSuccess) {
          this.tableData.rows = data.results
          this.tableData.totalRecords = data.totalRow
        } else {
          this.$store.commit('main/setToastError', data)
        }
        this.isShowLoading = false
      })
    },
    statusColor(statusId) {
      if(statusId === '1') {
        return 'primary'
      } else if (statusId === '2') {
        return 'danger'
      } else {
        return 'dark'
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>