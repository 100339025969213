<template>
  <div>
    <b-overlay :show="showLoading">
      <b-card :title="$t('work.headerDateOfWork')">
        <b-row v-for="(row, key) in result.workingDayLists" :key="`date-row-${key}`">
          <b-col cols="6" md="1">
            <b-form-group>
              <b-form-input :value="key | showNum" plaintext/>
            </b-form-group>
          </b-col>

          <b-col md="9" order="3" order-md="2">
            <b-form-group>
              <b-form-input :value="showDateMerge(row)" plaintext/>
              <small>{{ row.workExtendReason }}</small>
            </b-form-group>
          </b-col>

          <b-col cols="6" md="2" order="2" order-md="3">
            <b-form-group>
              <b-form-input :value="showWorkingIsHoliday(row.workIsHoliday)" plaintext class="text-right text-md-left"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay} from 'bootstrap-vue'

export default {
  name: "WorkViewDetail",
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
  },
  props: {
    result: {
      type: Object,
      required: true
    },
    isShowLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {
    showLoading() {
      return this.isShowLoading
    },
  },
  methods: {
    showWorkingIsHoliday(val) {
      return parseInt(val) === 1 ? this.$t('work.radioWorkingDay') : this.$t('work.radioHoliday')
    },
    showDateMerge(result) {
      if(result.workDayType === 2) {
        let approveStatus
        if(result.workIsApprove === 'Y') {
           approveStatus = 'Approved'
        } else if(result.workIsApprove === 'N') {
          approveStatus = 'Disapproved'
        } else if(result.workIsApprove === 'W') {
          approveStatus = 'Waiting for approve'
        }
        return `${result.workDatetimeMerge} (Extend, ${approveStatus})`
      } else {
        return result.workDatetimeMerge
      }
    }
  },
  filters: {
    showNum(val) {
      let num = val + 1
      return `#${num}`
    }
  }
}
</script>

<style scoped>

</style>