<template>
  <div>
    <b-overlay :show="isShowLoading">
      <b-card :title="$t('work.headerContractor')">
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('work.supervisor')">
              <b-form-input v-model="result.workContractorAdminName" plaintext/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group :label="$t('work.tel')">
              <b-form-input v-model="result.workContractorAdminTel" plaintext/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group :label="$t('work.workers')"/>
          </b-col>

          <b-col md="6" class="text-right">
            <b-button variant="secondary" @click="printAll" v-if="userData && userData.userGroupId === 4">
              <feather-icon icon="PrinterIcon"/>
              {{ $t('general.btnPrintAll') }}
            </b-button>
          </b-col>

          <b-col md="12" class="mb-2">
            <vue-good-table
                :columns="tableData.columns"
                :rows="tableData.rows"
                :isLoading.sync="tableData.isLoading"
                :pagination-options="{ enabled: false }"
            >
              <template
                  slot="table-row"
                  slot-scope="props"
              >
                <div v-if="props.column.field === 'workerImageProfile'" class="text-center">
                  <b-avatar size="70px" :src="props.row.workerImageProfile"/>
                </div>

                <div v-else-if="props.column.field === 'action'" class="text-nowrap text-center">
                  <b-button
                      type="button"
                      variant="info"
                      size="sm"
                      class="mr-1"
                      @click="viewWorker(props.row.userId, props.row.workerId)"
                  >
                    <feather-icon icon="SearchIcon"/>
                    {{ $t('general.btnView') }}
                  </b-button>
                  <b-button
                      type="button"
                      variant="secondary"
                      size="sm"
                      @click="setPrintCard(props.row.userId)"
                      v-if="userData && userData.userGroupId === 4"
                  >
                    <feather-icon icon="PrinterIcon"/>
                    {{ $t('general.btnPrint') }}
                  </b-button>
                </div>

                <div v-else>{{ props.formattedRow[props.column.field] }}</div>
              </template>
            </vue-good-table>
          </b-col>

          <b-col md="6">
            <b-form-group :label="$t('work.totoWorkOwner')">
              <b-form-input v-model="result.workWorkOwnerName" plaintext/>
            </b-form-group>
          </b-col>

        </b-row>
      </b-card>
    </b-overlay>

    <b-modal
        ref="modal-personal-information"
        cancel-variant="outline-secondary"
        :ok-title="$t('general.deleteConfirm')"
        hide-footer
        :cancel-title="$t('general.deleteCancel')"
        centered
        :title="$t('user.headerPersonalInformation')"
        size="xl"
    >
      <h3 class="mb-2">{{ $t('user.personalInformation') }}</h3>

      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('user.idCard')">
            <b-form-input v-model="userResult.userIdCard" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('user.prefix')">
            <b-form-input v-model="userResult.prefixName" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('user.firstName')">
            <b-form-input v-model="userResult.userFirstName" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('user.lastName')">
            <b-form-input v-model="userResult.userLastName" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('user.position')">
            <b-form-input v-model="userResult.positionName" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('user.tel')">
            <b-form-input v-model="userResult.userTel" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6" v-if="false">
          <b-form-group :label="$t('user.bloodType')">
            <b-form-input v-model="userResult.userBloodTypeName" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6" v-if="false">
          <b-form-group :label="$t('user.congenitalDisease')">
            <b-form-input v-model="userResult.userCongenitalDisease" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('user.email')">
            <b-form-input v-model="userResult.userEmail" plaintext/>
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="mb-2">{{ $t('user.emergencyContact') }}</h3>
      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('user.emergencyName')">
            <b-form-input v-model="userResult.userEmergencyName" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('user.emergencyTel')">
            <b-form-input v-model="userResult.userEmergencyTel" plaintext/>
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="mb-2">{{ $t('user.headerTrainingInformation') }}</h3>
      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('user.trainingLatestDate')">
            <b-form-input v-model="userResult.userLatestTrainingDate" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('user.trainingExpireDate')">
            <b-form-input v-model="userResult.userLicenseEndDate" plaintext/>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('user.trainingLatestScore')">
            <b-form-input v-model="userResult.userLatestTrainingScore" plaintext/>
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="mb-2">{{ $t('user.attachments') }}</h3>

      <vue-good-table
          :columns="userAttach.columns"
          :rows="userAttach.rows"
          :pagination-options="{ enabled: false }"
      >
        <template
            slot="table-row"
            slot-scope="props"
        >
          <div v-if="props.column.field === 'userAttachmentImage'" class="text-center">
            <thumbnail-file :src="props.row.userAttachmentImage"/>
          </div>

          <div v-else>{{ props.formattedRow[props.column.field] }}</div>
        </template>
      </vue-good-table>

      <hr>

      <b-row align-v="center" class="my-3">
        <b-col md="6">
          <b-form-group>
            <b-form-checkbox v-model="userResult.userBlacklist" value="Y" class="custom-control-primary" :disabled="!userData">
              {{ $t('user.blacklist') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col md="6" v-if="userResult.userBlacklist === 'Y'">
          <b-form-group :label="$t('user.attachmentDesc')">
            <b-form-input v-model="userResult.userBlacklistDesc"/>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="mt-3 text-right">
        <b-button variant="outline-secondary" class="mb-1 mr-1" @click="handleCancel">
          {{ $t('general.btnClose') }}
        </b-button>
        <b-button variant="success" class="mb-1"  @click="saveData" v-if="userData">
          <feather-icon icon="SaveIcon"/>
          {{ $t('general.btnSubmit') }}
        </b-button>
      </div>

    </b-modal>

  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BAvatar, BModal, BFormCheckbox} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import tableMixins from "@/mixins/tableMixins"
import {UserService, UserAttachmentService} from "@/services"
import masterMixins from "@/mixins/masterMixins"

const userService = new UserService()
const userAttachmentService = new UserAttachmentService()

export default {
  name: "WorkViewDetail",
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BAvatar,
    BModal,
    BFormCheckbox,
    VueGoodTable
  },
  props: {
    result: {
      type: Object,
      required: true
    },
    isShowLoading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [tableMixins, masterMixins],
  created() {
    this.tableData.columns = [
      {
        label: '',
        field: 'workerImageProfile',
        width: '10%',
        sortable: false
      }, {
        label: this.$t('user.idCard'),
        field: 'workerIdCard',
        width: '15%',
        sortable: false
      }, {
        label: this.$t('user.firstName'),
        field: 'workerUserFullName',
        width: '20%',
        sortable: false
      }, {
        label: this.$t('user.position'),
        field: 'workerPositionName',
        width: '10%',
        sortable: false
      },  {
        label: this.$t('user.licenseDateEnd'),
        field: 'workerLicenseEnd',
        width: '10%',
        sortable: false
      }, {
        label: '',
        field: 'action',
        width: '10%',
        sortable: false
      },
    ]
    this.tableData.rows = this.result.workerLists ? this.result.workerLists : []
    this.userAttach.columns = [
      {
        label: this.$t('user.attachmentFile'),
        field: 'userAttachmentImage',
        width: '30%',
        sortable: false
      }, {
        label: this.$t('user.attachmentType'),
        field: 'userAttachmentTypeName',
        width: '30%',
        sortable: false
      }, {
        label: this.$t('user.attachmentDesc'),
        field: 'userAttachmentDescription',
        width: '10%',
        sortable: false
      },
    ]
  },
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData')),
    isBtnDisabled: false,
    workerId: '',
    userAttach: {
      columns: [],
      rows: []
    },
    userResult: {},
    userCodeForPrint: []
  }),
  watch: {
    result(nVal) {
      this.tableData.rows = nVal.workerLists
    }
  },
  methods: {
    handleCancel() {
      this.$refs['modal-personal-information'].hide()
    },
    async saveData() {
      let saveResult = await userService.setBlacklist(this.userResult.userId, this.userResult.userBlacklist, this.userResult.userBlacklistDesc, this.workerId)
      if (saveResult.isSuccess) {
        this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
        this.$emit('work-update')
        this.$refs['modal-personal-information'].hide()
      } else {
        this.$store.commit('main/setToastError', saveResult.data)
      }
    },
    async viewWorker(userId, workerId) {
      this.workerId = workerId
      let {data: userResult} = await userService.getData(userId)
      let {data: userAttachResult} = await userAttachmentService.listData({userId: userId})

      this.userResult = userResult.result
      this.userResult.userBloodTypeName = this.showBloodType()
      this.userAttach.rows = userAttachResult.results
      this.$refs['modal-personal-information'].show()
    },
    showBloodType() {
      let optionBloodTypes = this.listBloodType()
      let bloodResult = optionBloodTypes.find(data => data.bloodId === this.userResult.userBloodType)
      return bloodResult ? bloodResult.bloodName : ''
    },
    printCard() {
      window.location.href=`${process.env.VUE_APP_URL_API}contractor-card?userCode=${this.userCodeForPrint.join(',')}`
    },
    setPrintCard(userCode) {
      this.userCodeForPrint = []
      this.userCodeForPrint.push(userCode)
      this.printCard()
    },
    printAll() {
      this.userCodeForPrint = this.tableData.rows.map(data => data.userId)
      this.printCard()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>